<template>
  <div>
     <v-list class="transparent">
       <v-list-item>
         <v-select v-model="ActiveSuiteApp.AppDefaultView" label="Default View" item-text="title"
          :items="ComputedActiveSuiteMenuItems" return-object clearable @change="UpdateAppProp('AppDefaultView')"
         />
       </v-list-item>
       <v-list-item>
         <v-select v-model="ActiveSuiteApp.AppDefaultTable" label="Default Table" item-text="id"
          :items="AppEntities" clearable @change="UpdateAppProp('AppDefaultTable')"
         />
       </v-list-item>
       <v-list-item>
         <v-select v-model="ActiveSuiteApp.AppNavbarSecondary" label="Navbar Secondary"
          :items="ComputedAppNavbarSecondaryOptions" clearable @change="UpdateAppProp('AppNavbarSecondary')"
         />
       </v-list-item>
       <!-- <v-list-item>
        
        <v-select v-model="SharedSystemPlugins" label="System Entities"
          :items="ComputedSystemEntities" multiple item-text="id" clearable @change="UpdateAppProp('SharedPlugins')"
         />
        
       </v-list-item> -->
     </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {
          AppNavbarSecondaryOptions: [
            {ID: 1000001,Name: 'Site Search',Plugins: ['Site_Search']},
            {ID: 1000002,Name: 'Warehouse Selector',Plugins: ['Warehouse_Outbound','Warehouse_Inbound']}
          ],
          SharedSystemPlugins: [],
        }
    },	
    computed:{
      AppEntities(){
            return this.SystemEntities.filter(ent => {
                return ent.Primary_Appid === this.ActiveSuiteApp.id
            })
        },
      ComputedSystemEntities(){
        return this.SystemEntities.filter(ent => {
          return this.$store.state.roottableentities.includes(ent.id) && this.RAPlugins.find(obj => obj.id === ent.id) || 
          this.$store.state.rootdbentities.includes(ent.id) && this.RAPlugins.find(obj => obj.id === ent.id)
        })
      },
      RAPlugins(){
            return this.$store.state.RAPlugins
        },
      ComputedActiveSuiteMenuItems(){
        return this.ActiveSuiteMenuItems.map(item => {
          let itemobj = {title: item.title,Parenttitle: item.Parenttitle}
          return itemobj
        })
      },
      ActiveSuiteMenuItems(){
        return this.$store.state.ActiveSuiteMenuItems
      },
      ComputedAppNavbarSecondaryOptions(){
        let appplugins = this.ActiveSuiteApp.Plugins.map(plug => {
          return plug.id
        })
        let sharedplugins = this.ActiveSuiteApp.SharedPlugins.map(plug => {
          return plug.id
        })
        // console.log(sharedplugins)
        let totalplugins = appplugins.concat(sharedplugins)
        return this.AppNavbarSecondaryOptions.filter(opt => {
          return opt.Plugins.some(r=> totalplugins.includes(r))
        }).map(opt => {
          return opt.Name
        })
      },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        AppsPath(){
        return this.$store.state.AppsPath
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      this.SharedSystemPlugins = this.ActiveSuiteApp.SharedPlugins.filter(plug => {
        return this.ComputedSystemEntities.find(obj => obj.id === plug.id)
      })
    },
    methods:{
      UpdateAppProp(prop){
        this.AppsPath.doc(this.ActiveSuiteApp.id).update({
          [prop]: this.ActiveSuiteApp[prop]
        })
      }
    }
}
</script>

<style>

</style>



